var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"slick",rawName:"v-slick"}],key:3,staticClass:"refCourseList newCourseSlide"},[_c('div',{staticClass:"slideItem"},[_c('a',{staticClass:"slideItem_dr",attrs:{"href":`https://theme.npm.edu.tw/3d/`,"target":"_blank","title":"在新視窗打開3D文物賞析鏈結(另開新視窗)"}},[_c('div',{staticClass:"courseInfo"},[_vm._m(0),_c('p',{directives:[{name:"plaintext",rawName:"v-plaintext"}]},[_vm._v(" 70件文物的3D與2D大圖賞析平台，內含文物介紹、影音、展覽、禮品推薦、典藏資料庫及Open data相關連結等豐富資源，提供喜愛故宮文物藝術的使用者透過縮放、轉動、光影顏色或紋理等細部文物材質外，更進一步的認識故宮館藏文物。 ")])]),_vm._m(1)])]),_c('div',{staticClass:"slideItem"},[_c('a',{staticClass:"slideItem_dr",attrs:{"href":`https://theme.npm.edu.tw/selection/`,"target":"_blank","title":"在新視窗打開典藏精選鏈結(另開新視窗)"}},[_c('div',{staticClass:"courseInfo"},[_vm._m(2),_c('p',{directives:[{name:"plaintext",rawName:"v-plaintext"}]},[_vm._v(" 精選故宮文物藝術圖像，提供【依類別】、【依朝代】、【依展場】、【依多媒體】，根據自己的喜好與研究，探索深度的文物故事。 ")])]),_vm._m(3)])]),_c('div',{staticClass:"slideItem"},[_c('a',{staticClass:"slideItem_dr",attrs:{"href":`https://tech2.npm.edu.tw/720vr/index.html`,"target":"_blank","title":"在新視窗打開720VR走進故宮(另開新視窗)"}},[_c('div',{staticClass:"courseInfo"},[_vm._m(4),_c('p',{directives:[{name:"plaintext",rawName:"v-plaintext"}]},[_vm._v(" 歡迎來到720°VR走進故宮，在這個虛擬實境空間裡，您可以隨時隨地參觀國立故宮博物院院區，快速瀏覽不同主題的重點展件。 ")])]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('b',[_vm._v("3D文物賞析")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"courseImg"},[_c('img',{attrs:{"src":"/img/DigitResource_1.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('b',[_vm._v("典藏精選")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"courseImg"},[_c('img',{attrs:{"src":"/img/DigitResource_2.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('b',[_vm._v("720VR走進故宮")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"courseImg"},[_c('img',{attrs:{"src":"/img/DigitResource_4.jpg","alt":""}})])
}]

export { render, staticRenderFns }