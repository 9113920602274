<template>
  <div v-show="Ready">
    <div class="sec2" >
      <div class="slideLogin">
        <div class="home-tabs">
          <router-link to="#" v-scroll-to="'#new'" class="courseStudentPage" title="點擊可跳至首頁最新課程">首頁最新課程區塊</router-link>
          <router-link to="#" v-scroll-to="'#hot'" class="courseStudentPage" title="點擊可跳至首頁熱門課程">首頁熱門課程區塊</router-link>
          <router-link to="#" v-scroll-to="'#digitResource'" class="courseStudentPage" title="點擊可跳至首頁數位資源">首頁數位資源區塊</router-link>
        </div> 
        <h1>
        <div class="mainSlide">
          <div class="slide" v-for="b in Banner" :key="b.BannerID">
            <a :href="b.TargetUrl" target="_blank" tltle="Banner資訊連結"><img :src="b.ImageUrl | imageCDN" alt="課程主圖圖示(另開新視窗)"/></a>
          </div>
        </div>
        </h1>
      </div>
    </div>
    <div class="main homeContent" id="main"> 
      <a href="#c" class="c" accesskey="c" title="中間主要內容區，此區塊呈現網頁的網頁內容">:::</a> 
      <div class="row new" id='new'>
        <div class="container">
          <div class="subTitle">
            <h2>
              <img src="/img/latestTitle-2.png" alt="最新課程標題圖示" />
            </h2>
          </div>
          <!--  George Modify 2023-12-31 TODO with AA Pass 2024-01-20 復原 -->
          <LessonSlider :loader="loadNew" :key="New.length" />
          <router-link class="moreCourse" to="/category/all" title="更多最新課程">更多最新課程
            <img src="/img/arrowx3.svg" alt="">
          </router-link>
        </div>
      </div>
      <br><br><!--  George Modify 2023-12-31 TODO with AA Pass 2024-01-20 復原-->
      <div class="row hot" id='hot'>
        <div class="container">
          <div class="subTitle">
            <h2>
              <img src="/img/hotTitle-2.png" alt="熱門課程標題圖示" />
            </h2>
          </div>
          <!--  George Modify 2023-12-31 TODO with AA Pass 2024-01-20 復原-->
          <LessonSlider :loader="loadHot" :key="Hot.length" />
            <router-link class="moreCourse" to="/category/all" title="更多熱門課程">更多熱門課程
            <img src="/img/arrowx3.svg" alt="">
          </router-link>
        </div>
      </div>
      <br><br><br><!--  George Modify 2023-12-31 TODO with AA Pass 2024-01-20 復原-->
      <div class="row digitResource" id='digitResource'>
        <div class="container">
          <div class="subTitle">
            <h2>
              <img src="/img/DigitResourceTitle.png" alt="數位資源標題圖示" />
            </h2>
          </div>
          <!--  George Modify 2023-12-31 TODO with AA Pass 2024-01-20 復原-->
          <DigitResourceSlider />
        </div>
      </div>
      <div class="middleWidget">
        <div class="container">
          <div class="socialLinks">
            <a :href="Setting.YouTube" target="_blank" title = 'Youtube圖標icon'
              ><img src="/img/icon-YT.svg" alt="Youtube圖標icon(另開新視窗)"/></a
            ><a :href="Setting.Facebook" target="_blank" title='Fb圖標icon'
              ><img src="/img/icon-FB.svg" alt="Fb圖標icon(另開新視窗)"/></a
            ><a :href="Setting.Instagram" target="_blank" title='Ig圖標icon'
              ><img src="/img/icon-IG.svg" alt="Ig圖標icon(另開新視窗)"
            /></a><a :href="Setting.Podcast" target="_blank" title='Podcast圖標icon'
              ><img src="/img/icon-podcast.svg" alt="Podcast圖標icon(另開新視窗)"
            /></a>
          </div>
          <div class="searchColumn">
            <div class="searchFrame">
              <input
                type="text"
                placeholder="搜尋更多"
                title="搜尋"
                v-model="Keyword"
                v-on:keyup.enter="search"
              /><a
                class="searchSubmit"
                href="javascript:void(0);"
                title="點擊前往課程關鍵字搜尋結果"
                @click="search"
                ><img src="/img/zoom-blue.svg" alt = "在輸入框輸入關鍵字後點擊可查詢課程資訊"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DigitResourceSlider from "@/components/DigitResourceSlider.vue";
import LessonSlider from "@/components/LessonSlider.vue";
export default {
  components: {
    DigitResourceSlider,
    LessonSlider,
  },
  data() {
    return {
      Banner: [],
      Ready: false,
      Keyword: "",
      New: [],
      Hot: [],
      Setting: {
        YouTube: "",
        Facebook: "",
        Instagram: "",
      },
    };
  },
  async mounted() {
    this.setBase("home-wide unlogin");

    var response = await this.$api.getHome();
    this.Banner = response.Banner;
    this.New = response.New;
    this.Hot = response.Hot;
    if (response.Setting) {
      this.Setting = $.extend(this.Setting, JSON.parse(response.Setting));
    }

    //George Modify 2023-12-29 TODO with AA Pass 2024-01-20 復原
    this.$nextTick(() => {
      $(".mainSlide").slick({
        arrows: false,
        autoplay: true,
        dots: true,
        //- adaptiveHeight: true
      });
    });

    this.Ready = true;
    this.loadCustomJs();
  },
  methods: {
    search() {
      if (this.Keyword) {
        this.$router.push("/search/" + encodeURIComponent(this.Keyword));
      }
    },
    loadNew() {
      return new Promise((resolve, reject) => resolve(this.New));
    },
    loadHot() {
      return new Promise((resolve, reject) => resolve(this.Hot));
    },
    async loadCustomJs() {
      try {
        await $.getScript(window.filters.imageCDN("custom.js"));
      } catch (e) {}
    },
  },
};
</script>
