<template>
  <div>
    <div class="topbar_o">
      <div class="container">
        <div class="topInner">
          <template v-if="User">
            <template v-if="User.Type == 1">
              <div class="tSpaceLink">
                <span>教師專區</span>
                <ul class="dDown-menu">
                  <li>
                    <router-link to="/teacher/class/my" title="點擊可進入本人授課班級">我的班級</router-link>
                  </li>
                  <li>
                    <router-link to="/teacher/class/create" title="點擊可建立班級"
                      >建立班級</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/teacher/class/trace" title="點擊可查看學習進度"
                      >學習進度追蹤</router-link
                    >
                  </li>
                </ul>
              </div>
              <div class="loggedInUser">
                <span>Hi, {{ User.Name }}老師</span>
                <ul class="dDown-menu">
                  <li><router-link to="/history" title="點擊可查看歷史紀錄">觀看紀錄</router-link></li>
                  <li><router-link to="/profile" title="點擊可編輯個人資料">編輯個人資料</router-link></li>
                  <li>
                    <a href="javascript:void(0);" title="點擊可登出此系統" @click="logout">登出</a>
                  </li>
                </ul>
              </div>
            </template>
            <template v-else-if="User.Type == 2">
              <div class="tSpaceLink">
                <span>學生專區</span>
                <ul class="dDown-menu">
                  <li><router-link to="/student/class/my" title="點擊可進入本人課程">我的課程</router-link></li>
                  <li><router-link to="/student/class/add" title="點擊可加入此課程">加入課程</router-link></li>
                  <li><router-link active-class="act" to="/student/history" title="點擊可查看上課紀錄">上課紀錄</router-link></li>
                </ul>
              </div>
              <div class="loggedInUser">
                <span>Hi, {{ User.Name }}您好</span>
                <ul class="dDown-menu">
                  <li><router-link to="/profile" title="點擊可編輯個人資料">編輯個人資料</router-link></li>
                  <li>
                    <a href="javascript:void(0);" title="點擊可登出此系統" @click="logout">登出</a>
                  </li>
                </ul>
              </div>
            </template>
            <template v-else>
              <router-link to="/history" title="點擊可查看歷史紀錄">觀看紀錄</router-link>
              <div class="loggedInUser">
                <span>Hi, {{ User.Name }}您好</span>
                <ul class="dDown-menu">
                  <li><router-link to="/profile" title="點擊可編輯個人資料">編輯個人資料</router-link></li>
                  <li>
                    <a href="javascript:void(0);" title="點擊可登出此系統" @click="logout">登出</a>
                  </li>
                </ul>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
    <header>
      <div class="container">
        <a href="#main" id="gotocenter" title="移到主要內容" class="sr-only sr-only-focusable">跳到主要內容</a>
        <div class="logo">
          <router-link to="/" title="點擊可回首頁"><img src="/img/logo.png" alt="網頁最上方故宮Logo圖"/></router-link>
        </div>
        <div class="main-menu">
          <ul>
            <li>
              <router-link to="/category/all" class="courseStudentPage" title="點擊可顯示學生課程">學生課程</router-link>
              <!-- <ul class="sub-menu">
                <li><router-link to="/category/all" class="courseAllPage" title="點擊可顯示全部資訊">全部</router-link></li>
                <li
                  v-for="c in LessonCatrgories"
                  :key="c.LessonCategoryID"
                  v-show="c.LessonCategoryID > 1"
                >
                  <router-link :to="'/category/' + c.LessonCategoryID" class="courseTeachPage" title="點擊可顯示">{{
                    c.CategoryName
                  }}</router-link>
                </li>
              </ul> -->
            </li>
            <li><router-link to="/category/1" class="courseTeachPage" title="點擊可顯示師培課程">師培課程</router-link></li>
            <li><router-link to="/techplans" class="techPlanPage" title="點擊可顯示教學資源">教學資源</router-link></li>
            <li><router-link to="/digitresources"  class="digitresourcePage" title="點擊可顯示數位資源">數位資源</router-link></li>
            <li v-if="!User"><router-link to="/login" class="loginPage" title="點擊可登入會員">登入</router-link></li>
            <li v-if="!User"><router-link to="/register" class="logoutPage" title="點擊可註冊會員">註冊</router-link></li>
            <li class="zoom">
              <a href="javascript:void(0);" title="點擊前往 關閉[課程關鍵字搜尋]" @click="showSearch">
                <img src="/img/zoom-w.png" alt="提示大家可按下放大鏡搜尋課程"/>
              </a>
            </li>
          </ul>
          <div class="h_search">
            <input type="text" placeholder="搜尋更多" title="搜尋" v-model="Keyword" v-on:keyup.enter="search" :tabindex="TabIndexNo" aria-labelledby="menuItem-display"/>
            <a
              id="menuItem-display"
              href="javascript:void(0);"
              title="點擊前往課程關鍵字搜尋結果"
              :tabindex="TabIndexNo"
              @click="search"
              ><img src="/img/send.svg" alt="在輸入框輸入關鍵字後點擊可查詢課程資訊"
            /></a>
          </div>
         
        </div>
         <div class="menu_burger">
            <span></span>
            <span></span>
            <span></span>
          </div>
      </div>
    </header>
  </div>
</template>
<script>
export default {
  data() {
    return {
      User: null,
      LessonCatrgories: [],
      Keyword: "",
      TabIndexNo : -1,
    };
  },
  async mounted() {
    this.setBase("home-wide");
    this.LessonCatrgories = await this.$api.getLessonCategories();
    this.User = this.$user.user;
    this.$user.addOnUpdateHandler((user) => {
      this.User = user.user;
    });
    this.$nextTick(()=>{
      $(".menu_burger").click(function () {
        if ($(".main-menu>ul").hasClass("show")) {
          $(".main-menu>ul").removeClass("show");
          $(".menu_burger").removeClass("on");
          $(".h_search").removeClass("act");
        } else {
          $(".main-menu>ul").addClass("show");
          $(".menu_burger").addClass("on");
        }
        
      });


      $(".menu_burger, header, .main").click(function () {
        
        $(".tSpaceLink .dDown-menu").removeClass("show");
        $(".sSpaceLink .dDown-menu").removeClass("show");
        $(".loggedInUser .dDown-menu").removeClass("show");
        
      });
        $(".main-menu li").click(function () {
          if ($(".main-menu>ul").hasClass("show")) {
            $(".main-menu>ul").removeClass("show");
            $(".menu_burger").removeClass("on");
          } 
        });
      $(".main-menu li:not('.zoom'), .h_search img").click(function () {
          $(".main-menu>ul").removeClass("show");
          $(".menu_burger").removeClass("on");
          $(".h_search").removeClass("act");
      });



      $(".loggedInUser > span").click(function () {
        if ($(".loggedInUser .dDown-menu").hasClass("show")) {
          $(".loggedInUser .dDown-menu").removeClass("show");
        } else {
          $(".loggedInUser .dDown-menu").addClass("show");
        }

          $(".tSpaceLink .dDown-menu").removeClass("show");
      });

      $(".tSpaceLink > span").click(function () {

          $(".loggedInUser .dDown-menu").removeClass("show");
        if ($(".tSpaceLink .dDown-menu").hasClass("show")) {
          $(".tSpaceLink .dDown-menu").removeClass("show");
        } else {
          $(".tSpaceLink .dDown-menu").addClass("show");
        }

      });

      $(".sSpaceLink > span").click(function () {
        if ($(".sSpaceLink .dDown-menu").hasClass("show")) {
          $(".sSpaceLink .dDown-menu").removeClass("show");
        } else {
          $(".sSpaceLink .dDown-menu").addClass("show");
        }
          $(".loggedInUser .dDown-menu").removeClass("show");
      });

      $(".topbar_o .dDown-menu a").click(function () {
          $(".dDown-menu").removeClass("show");
      });
    });
  },
  methods: {
    logout() {
      this.$user.logout();
      this.User = null;
      this.$router.replace('/login');
    },
    showSearch() {
      if ($(".h_search").hasClass("act")) {
        this.TabIndexNo = -1;
        $(".h_search").removeClass("act");
      } else {
        this.TabIndexNo = 0;
        $(".h_search").addClass("act");
      }
      
    },
    search() {
      if (this.Keyword) {
        this.$router.push("/search/" + encodeURIComponent(this.Keyword));
        this.TabIndexNo = -1;
      }      
    },
  },
};

</script>