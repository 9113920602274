import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: '首頁-故宮線上學校'}
  },
  {
    path: '/Art',
    name: 'HomeOld',
    component: Home,
    meta: { title: '首頁-故宮線上學校'}
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: '登入-故宮線上學校'},
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    meta: { title: '註冊-故宮線上學校'},
    component: () => import('../views/Register.vue'),

  },
  {
    path: '/teacher/class/my',
    name: 'TeacherMyClass',
    meta: { title: '老師班級-故宮線上學校'},
    component: () => import('../views/Teacher/MyClass.vue'),

  },
  {
    path: '/teacher/class/create',
    name: 'TeacherCreateClass',
    meta: { title: '老師建立班級-故宮線上學校'},
    component: () => import('../views/Teacher/CreateClass.vue'),

  },
  {
    path: '/teacher/class/create2',
    name: 'TeacherCreateClassPrev',
    meta: { title: '老師建立先前班級-故宮線上學校'},
    component: () => import('../views/Teacher/CreateClass.vue'),

  },
  {
    path: '/teacher/class/copy/:id',
    name: 'TeacherCopyClass',
    meta: { title: '老師複製班級-故宮線上學校'},
    component: () => import('../views/Teacher/CreateClass.vue'),
  },
  {
    path: '/teacher/class/edit/:id',
    name: 'TeacherEditClass',
    meta: { title: '老師編輯班級-故宮線上學校'},
    component: () => import('../views/Teacher/CreateClass.vue'),
  },
  {
    path: '/teacher/class/create/lessons',
    name: 'TeacherCreateClassSelectLesson',
    meta: { title: '老師班級選擇課程-故宮線上學校'},
    component: () => import('../views/Teacher/SelectLesson.vue'),
  },
  {
    path: '/teacher/class/create/info',
    name: 'TeacherCreateClassClassInfo',
    meta: { title: '老師建立班級資訊-故宮線上學校'},
    component: () => import('../views/Teacher/ClassInfo.vue'),
  },
  {
    path: '/teacher/class/create/homework',
    name: 'TeacherCreateClassAssignHomework',
    meta: { title: '老師班級作業-故宮線上學校'},
    component: () => import('../views/Teacher/AssignHomework.vue'),
  },
  {
    path: '/teacher/class/trace',
    name: 'TeacherTraceClass',
    meta: { title: '老師班級追蹤-故宮線上學校'},
    component: () => import('../views/Teacher/TraceClass.vue'),
  },
  {
    path: '/lesson/:id',
    name: 'Lesson',
    meta: { title: '課程-故宮線上學校'},
    component: () => import('../views/Lesson.vue'),
  },
  {
    path: '/lesson/chapter/:id',
    name: 'LessonChapter',
    meta: { title: '課程章節-故宮線上學校'},
    component: () => import('../views/LessonChapter.vue'),
  },
  {
    path: '/category/:id',
    name: 'LessonList',
    meta: { title: '課程分類-故宮線上學校'},
    component: () => import('../views/LessonList.vue'),
  },
  {
    path: '/search/:id',
    name: 'Search',
    meta: { title: '搜尋列表-故宮線上學校'},
    component: () => import('../views/Search.vue'),
  }, 
  {
    path: '/student/class/my',
    name: 'StudentMyClass',
    meta: { title: '學生班級-故宮線上學校'},
    component: () => import('../views/Student/MyClass.vue'),
  },
  {
    path: '/student/class/add',
    name: 'StudentAddClass',
    meta: { title: '學生加入班級-故宮線上學校'},
    component: () => import('../views/Student/AddClass.vue'),
  }, 
  {
    path: '/homework/:id',
    name: 'HomeWork',
    meta: { title: '學生作業-故宮線上學校'},
    component: () => import('../views/HomeWork.vue'),
  },
  {
    path: '/student/history',
    name: 'StudentHistory',
    meta: { title: '學生歷史觀看紀錄-故宮線上學校'},
    component: () => import('../views/Student/History.vue'),
  },
  {
    path: '/history',
    name: 'History',
    meta: { title: '歷史觀看紀錄-故宮線上學校'},
    component: () => import('../views/History.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { title: '會員資料-故宮線上學校'},
    component: () => import('../views/Profile.vue'),
  },
  {
    path: '/fpassword',
    name: 'ForcePassword',
    meta: { title: '會員修改密碼-故宮線上學校'},
    component: () => import('../views/ForcePassword.vue'),
  },
  {
    path: '/techplans/:id?',
    name: 'TechPlanList',
    meta: { title: '教學資源列表-故宮線上學校'},
    component: () => import('../views/TechPlanList.vue'),
  },
  {
    path: '/techplan/:id',
    name: 'TechPlan',
    meta: { title: '教學資源-故宮線上學校'},
    component: () => import('../views/TechPlan.vue'),
  },
  {
    path: '/digitresources',
    name: 'DigitResourceList',
    meta: { title: '數位資源列表-故宮線上學校'},
    component: () => import('../views/DigitResourceList.vue'),
  },
  {
    path: '/edu/callback',
    name: 'OAuthCallback',
    meta: { title: 'OpenID回調網址-故宮線上學校'},
    component: () => import('../views/OAuthCallback.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    meta: { title: '隱私權-故宮線上學校'},
    component: () => import('../views/Privacy.vue'),
  },
  {
    path: '/security',
    name: 'Security',
    meta: { title: '安全政策-故宮線上學校'},
    component: () => import('../views/Security.vue'),
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    meta: { title: '網站導覽-故宮線上學校'},
    component: () => import('../views/Sitemap.vue'),
  },
  {
    path: '/forget',
    name: 'Forget',
    meta: { title: '忘記密碼-故宮線上學校'},
    component: () => import('../views/Forget.vue'),
  },
  {
    path: '/mail/forget',
    name: 'Forgot',
    meta: { title: '忘記密碼信箱重設密碼-故宮線上學校'},
    component: () => import('../views/Forgot.vue'),
  },
  { 
    path: '/404', 
    name: 'NotFound', 
    meta: { title: '沒有任何資源錯誤畫面-故宮線上學校'},
    component: () => import('../views/NotFound.vue'), 
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'NotFound',
    meta: { title: '沒有任何資源錯誤畫面-故宮線上學校'},
    component: () => import('../views/NotFound.vue'), 
  },
]


const router = new VueRouter({
  mode: 'history',
  routes
})


export default router
